body {
	background-color: #fff;
	font-size: 0.85rem;
}
.App {
	height: 100vh;
	position: relative;
	letter-spacing: -.05em;
	overflow: auto;
	background-color: #fff;
	/* background: linear-gradient(180deg, rgba(179, 215, 182, 1) 0%, rgba(15, 135, 151, 1) 100%); */
	/* background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 50%,
		rgba(179, 215, 182, 1) 50%,
		rgba(15, 135, 151, 1) 100%
	); */
	-webkit-focus-ring-color: none;

	/* background: linear-gradient(180deg, rgba(80, 29, 76, 1) 0%, rgba(44, 32, 77, 1) 50%, rgba(20, 16, 42, 1) 100%); */
}
.container {
	margin: auto;
	height: 100%;
	min-width: 320px;
	/* max-width: 400px; */
}
.nav-container svg {
	color: white;
}
button:focus {
	outline: 0;
}
#goFS:-webkit-full-screen {
	width: 100%;
	height: 100%;
}
*:focus {
	outline: none;
}
